import * as React from 'react'
import { Link } from 'gatsby'


export default function FourDayBanner () {
    return (
        <Link to='/fourdayweek' className="w-full mx-auto">
            <div className='bg-fourday-skyblue mx-auto p-4 transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none mx-auto '>
                <h3 className='text-xl lg:text-3xl xl:text-4xl font-oswald text-center '><span className='mr-4 text-fourday-blue'>SOMETIMES 4 IS BETTER THAN 5<br className='block md:hidden'/></span><span className='text-white font-light'>WE’RE SWITCHING TO A 4 DAY WEEK | CLICK TO FIND OUT WHY</span></h3>
            </div>
        </Link>
    )
}