import { graphql, Link } from 'gatsby'
import * as React from 'react'
import Layout from '../components/layout'
import Clients from '../components/Clients'
import FourDayBanner from '../components/fourdaybanner'
import '../styles/global.css'
import Zoom from 'react-reveal/Zoom' // Importing Zoom effect
import ExpandableCards from '../components/ExpandableCards'
import {Helmet} from "react-helmet";


const cards = [
  { id: 1, cardName: 'Co-Designing Policies and Strategies', text: 'We support governments and councils in citizen engagement to promote civically owned policies and strategies.', image: "img/projects/bg/banana-block-camera-shot.webp", active: true },
  { id: 2, cardName: 'Public Space and Wellbeing', text:  'Across all of our projects we design to improve wellbeing and mental health outcomes in place.', image: "img/projects/bg/pillar-relink.webp", tag: 'Public Space & Placemaking', active: false},
  { id: 3, cardName: 'Cultural Projects', text:  'We are committed to culture-led community-driven interventions. Everything we do is designed with people, for people.', image: "img/projects/bg/pillar-placeinspace.webp", active: false},
  { id: 4, cardName: 'Technology for good', text:  'We develop digital platforms with social impact.', image: "/img/videos/foyle_projects_gif.gif", active: false}
];
 

export default function Home({ data }) {
  console.log(data)
  return (
    <Layout pageTitle="Home Page">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Urban Scale Interventions</title>
        <meta name="description" content="Urban Scale Interventions is a people-centred design and innovation studio tackling issues in the places we live, work and play."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>
      
      <video className="w-full z-0" id="bgvid" autoPlay muted loop src="https://player.vimeo.com/progressive_redirect/playback/669500719/rendition/1080p/1080p.mp4?loc=external&signature=b336123530e9b4c9987ac2f11a2d16807a244f0997fedff17a5ab480efeb753f"></video>
      
      <FourDayBanner/>


      <div className="md:p-8 pl-3 pr-3 pt-5 pb-5 top">
      <div className="w-full font-semibold lg:w-1/2 4xl:w-1/2 p-5 sm:pl-12">
          <h1 className=" text-4xl sm:text-5xl md:text-6xl leading-tight font-bold 2xl:py-24">We tackle challenges in the places we live, work and play.</h1>
      </div>

      <div className="sm:pl-12 pb-16">
        <div className="flex flex-wrap -mb-4 md:mb-0">
          <div className="w-full lg:w-1/2 mb-4 md:mb-0">
            <p
              className="text-xl md:text-3xl md:pt-6 px-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16 md:mr-20">Through
              creative
              engagement, impactful design and
              quality
              delivery, we help cities, governments and organisations to improve the lives of the people they
              serve.<br/><br/>
              From reframing highstreets, to new models of education, playful interventions, regenerative tourism or
              improved community wellbeing, we cut through the red tape, take risk, see the bigger picture and challenge
              the status quo.</p>
          </div>


          <div className="w-full lg:w-1/2 mb-4 md:mb-0 ">
            <div className="w-full">
              <Link to = "/projects"
                className="font-semibold w-4/5 sm:w-full arrow mb-12 ml-6 sm:mb-3 sm:ml-0 mt-6 sm:mt-4 text-black text-2xl md:text-3xl hover:text-black">
                Find out more about<br/>our projects and process
                <span></span>
              </Link>
            </div>
            <img data-scroll data-scroll-speed="2" src="https://ik.imagekit.io/usi/home/after_ZfiX75EFC.jpg"
              alt="urban forest"/>
          </div>
        </div>
      </div>
    </div>


    <section className="py-12 px-4 text-center bg-gray-400">
      <div className="w-full max-w-4xl mx-auto">
        <h2 className="text-5xl mt-24 leading-tight font-semibold font-heading">Why</h2>
        <h2 className="leading-tight text-5xl md:text-6xl pt-3 font-semibold">In a world of <span className="Spotlight leading-none" data-text= "black and white">black and white</span> we
          play in grey
        </h2>
        <p className="text-xl md:text-3xl pt-9 mb-24">Whilst some forms of science and engineering deal in the
          black and white, the world we live in is more complicated. People are unpredictable, problems are open ended, solutions
          are innumerable, contexts are unique and everything is interconnected. From highstreets to education, climate to
          tourism we engage people, design new narratives and deliver change for cities, sectors and stakeholders. </p>
      </div>
    </section>

    <div className="flex flex-wrap md:mb-0">
      <div className="h-auto w-full md:w-1/2 md:mb-0"><img className="w-full"
          src="https://ik.imagekit.io/usi/home/left_IGz54dVck.png" alt=""/></div>
      <div className="h-auto w-full md:w-1/2 md:mb-0"><img className="w-full"
          src="https://ik.imagekit.io/usi/home/right_ry2f9MbXZboz.png" alt=""/></div>
    </div>

    <div className="md:p-8 pl-3 pr-3 pt-5 pb-5 top">
      <section className="w-full p-5 sm:pl-12">
        <h2 data-scroll data-scroll-speed="1" className="text-5xl mb-8 pt-9 leading-tight font-semibold font-heading">Who we
          are</h2>
        <div className="flex flex-wrap -mx-2">
          <div className="lg:w-2/5 px-2 lg:pr-16 mb-6 lg:mb-0">
            <h2 data-scroll data-scroll-speed="2" className="leading-tight text-4xl md:text-6xl font-semibold">Designers,<br/>
              thinkers,<br/> makers, <br/>and doers</h2>
          </div>
          <div className="lg:w-3/5 px-2">
            <p data-scroll data-scroll-speed="2" className="text-xl md:text-3xl mb-24 md:mr-12">
              We’re a
              purposely broad team of researchers,
              architects,
              designers, strategists, filmmakers, producers, planners, technologists and makers. The perfect balance of
              inquisitive minds to push new ways of thinking and experienced hands to deliver tangible interventions.
              Being broad ensures the outcome of our projects are defined by insights and opportunities, not by the
              skills
              of the team.<br/><br/>

              We use these skills to change policy, deliver physical interventions, shape places, tell stories and
              create
              technology for people . We communicate complexity through visions, film and stories.
            </p>
          </div>
        </div>
      </section>
    </div>


    
    <div className="flex flex-wrap md:mb-0">
      <div className="w-full lg:w-2/5 md:mb-0"><img className="w-full h-full"
          src="https://ik.imagekit.io/usi/home/string-wall_jY48hurHW.png" alt=""/></div>
      <div className="w-full lg:w-3/5 md:mb-0 3xl:pt-32 bg-pink-900 p-8 md:p-20">
        <div className="self-center">
          <h2 data-scroll data-scroll-speed="1"
            className="text-5xl mb-8 leading-tight font-semibold font-heading text-gray-200">Process</h2>

          <h2 data-scroll data-scroll-speed="1" className="leading-tight text-4xl md:text-6xl text-white font-semibold">
            Academic rigour with practical
            applications</h2>
          <p data-scroll data-scroll-speed="1" className="text-xl md:text-3xl mt-12 text-white">We believe the
            best innovation happens at the
            intersection between people, place and purpose. <br/><br/>This philosophy, delivered through the Design
            Council’s
            established Double-Diamond Design methodology means we actually deliver change as well as academically
            rigorous thinking and models.
          </p>
        </div>
      </div>
    </div>


    <div id="end" className="md:p-8 pl-3 pr-3 pt-5 pb-5 top bg-blue-100">
      <section className="w-full p-5 sm:pl-12">
        <h2 data-scroll data-scroll-speed="1" className="text-5xl mb-8 pt-9 leading-tight font-semibold font-heading">We’re
          (not) special!</h2>

        <div className="flex flex-wrap -mx-2">
          <div className="lg:w-2/5 px-2 lg:pr-16 mb-6 lg:mb-0">
            <h2 data-scroll data-scroll-speed="2" className="leading-tight text-4xl md:text-6xl font-semibold">Sometimes, simplicity is
              harder than complexity</h2>
          </div>
          <div className="lg:w-3/5 px-2">
            <p data-scroll data-scroll-speed="2" className="text-xl md:text-3xl mb-24 md:mr-12">We don’t
              claim to be Einsteins. In fact what
              we
              do is
              simple. We talk to people to reframe challenges around their needs and deliver solutions that are more
              creative, relevant and align to key policy outcomes.

              But we do believe we’re unique. Cutting through the complexity is the hard part, and that’s what we do
              best.

            </p>
          </div>
        </div>
      </section>
    </div>

    <div className="flex justify-center items-center w-screen h-auto bg-gray-800 relative">
	    <div className="mx-auto mt-10 mb-10 lg:mb-40 w-5/6">
          <div className="p-6">
            <h2 className="text-2xl md:text-3xl 2xl:text-5xl py-4 font-semibold text-white">We do this through the
              four key pillars below</h2>
          </div>
          
          <ExpandableCards data={cards}/>
            <div className="bg-gray-800 p-12">
              <Link to="/services" className="marrow marrow-white text-xs md:text-sm text-white mt-12 w-4/6 lg:w-6/6">A full list of
              the services we
              provide
              <span></span>
              </Link>
            </div>         
       </div>
    </div>



    <Clients/>

    <div className="max-w-8xl mx-auto">
      <div
        className="bg-blue-900 relative px-4 py-12 overflow-hidden sm:px-8 sm:py-32 lg:px-20 lg:py:20 xl:px-20 xl:py:24">
        <h2 data-scroll data-scroll-speed="1"
          className="w-full md:w-1/2 leading-tight text-4xl md:text-6xl font-semibold font-display text-white sm:text-3xl">
          We're just getting
          started&nbsp; <span className="inline-block"></span>
        </h2>
        <p data-scroll data-scroll-speed="1" className="mt-8 w-full md:w-2/5 text-xl md:text-3xl text-white">
          Want to hear from us when we launch new projects? Sign up for our newsletter and we'll email you with
          updates!
        </p>

        <form action="https://formspree.io/f/xgepdgjd" method="POST" className="mt-6">
          <div className="sm:flex">
            <input type="email" required placeholder="Enter your email" name="email"
              className="block sm:max-w-xs w-full px-4 py-3 text-base appearance-none border border-gray-300 shadow-none bg-white rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300"/>
            <button type="submit"
              className="mt-4 relative sm:mt-0 sm:h-auto sm:ml-4 block w-full sm:w-auto border border-transparent px-6 py-3 text-base font-semibold leading-snug bg-blue-200 text-black rounded-md shadow-md hover:bg-gray-800 focus:outline-none focus:bg-gray-800 transition ease-in-out duration-150 hover:bg-blue-100"
             >Subscribe<span/>

            </button>
          </div>

        </form>

        <div className="hidden lg:block absolute inset-y-0 lg:left-2/3 xl:left-1/2 right-0">
          <img className="w-full h-full object-cover object-left"
            src="https://ik.imagekit.io/usi/home/wall_JWL3Zfq4uUmR.png" alt=""/>
        </div>
      </div>
    </div>

    </Layout>
  )
}


